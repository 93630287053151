root {
    --fsd-background-color: 'none';
    --fsd-color-white: '#ffff';
    --fsd-color: '#fa0ee9';
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-fsd] {
    outline: 2px solid var(--fsd-color);
    position: relative;
    background-color: var(--fsd-background-color);
}

[data-fsd]::after {
    content: attr(data-fsd);
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    background: var(--fsd-color);
    color: #ffff;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    padding: 0 2px;
    font-family: sans-serif;
}
